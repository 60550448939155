import { createRouter, createWebHistory } from 'vue-router';
import { routeGuard } from '../plugins/auth.js'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
  },
  {
    path: '/claim',
    name: 'Claim',
    beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: "claim" */ '../views/Claim.vue'),
  },
  {
    path: '/notioncallback',
    name: 'Notioncallback',
    beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: "notioncallback" */ '../views/Notioncallback.vue'),
  },
  {
    path: '/connect/:address',
    name: 'Connect',
    beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: "connect" */ '../views/Connect.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
  },
  {
    path: '/upgrade',
    name: 'Upgrade Plan',
    beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: "upgrade" */ '../views/Upgrade.vue'),
  },
  {
    path: '/support',
    name: 'Support',
    beforeEnter: routeGuard,
    component: () => import(/* webpackChunkName: "support" */ '../views/Support.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
