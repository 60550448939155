import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from 'axios'
import VueAxios from 'vue-axios'

import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'

import authConfig from '../auth_config.json'
import { setupAuth } from './plugins/auth.js'
import { setupApollo } from './plugins/apollo.js'

import './index.css'

var enableSentry = true
if (process.env.NODE_ENV === "development") {
  enableSentry = false
}

if (enableSentry) {
  Sentry.init({
    dsn: 'https://7ba002f5d6164b28bd29548872fa2909@o856390.ingest.sentry.io/5820130',
    release: 'app.blockbase.dev@' + process.env.npm_package_version,
    environment: process.env.NODE_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    logErrors: true,
  })
}

var app = createApp(App).use(store).use(router)

app.use(VueAxios, axios)

if (enableSentry) {
  app.config.errorHandler = (err, vm, info) => {
    Sentry.captureException(err)
  }
  window.addEventListener('error', (event) => {
    Sentry.captureException(event)
  })
  window.addEventListener('unhandledrejection', (event) => {
    Sentry.captureException(event)
  })
}

function callbackRedirect(appState) { router.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : '/'
  );
}

setupAuth(authConfig, callbackRedirect).then((auth) => {
  setupApollo().then((apollo) => {
    app.use(apollo).use(auth).mount('#app')
  })
})
