import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client/core';

export const setupApollo = async () => {
  var httpLink = createHttpLink({
    uri: 'https://gqapi.blockbase.dev/v1/graphql',
    headers: {}
  });

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }

  const ac = new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions,
  });

  return {
    install: (app, options) => {
      app.config.globalProperties.$apollo = ac
    }
  }
}
